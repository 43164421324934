import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import banner_img from "../assets/banner_1.png";

const CascadingDropdown = () => {
  const navigate = useNavigate();
  const [course, setCourse] = useState("");
  const [branch, setBranch] = useState("");
  const [semester, setSemester] = useState("");

  useEffect(() => {
    if (course && branch && semester) {
      navigate(`/${course}/${branch}/${semester}`);
    }
  }, [course, branch, semester, navigate]);

  const handleCourseChange = (event) => {
    setCourse(event.target.value);
  };

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };

  const handleSemesterChange = (event) => {
    setSemester(event.target.value);
  };

  return (
    <div className="container">
      <section id="right">
        <img  src={banner_img} alt="banner_image" />
      </section>
      <section id="left">
        <div className="select-container">
          <div className="select">
            <select value={course} onChange={handleCourseChange}>
              <option value="">Select Course </option>
              <option value="btech">BTech</option>
              {/* <option value="otherCourses">Other Courses</option> */}
            </select>
          </div>
          <div className="select">
            <select value={branch} onChange={handleBranchChange}>
              <option value="">Select Branch</option>
              {course === "btech" && (
                <>
                  <option value="cse">CSE</option>
                  <option value="aiml">AIML</option>
                  <option value="aids">AIDS</option>
                  <option value="it">IT</option>
                </>
              )}
              {/* {course === "otherCourses" && (
                <option value="coding_courses_notes">
                  Coding Courses Notes
                </option>
              )} */}
            </select>
          </div>
          {/* <div className="select">
            <select value={semester} onChange={handleSemesterChange}>
              <option value="">Select Semester</option>
              {semester === "cse" && (
                <>
                  <option value="1st">1st Semester</option>
                  <option value="2nd">2nd Semester</option>
                  <option value="3rd">3rd Semester</option>
                  <option value="4th">4th Semester</option>
                </>
              )}
              {semester === "coding notes" && (
                <option value="codingcourses">
                  Coding Courses Notes
                </option>
              )}
            </select>
          </div> */}
          <div className="select">
            <select value={semester} onChange={handleSemesterChange}>
              <option value="">Select Semester</option>
              <option value="1st">1st Semester</option>
              <option value="2nd">2nd Semester</option>
              <option value="3rd">3rd Semester</option>
              <option value="4th">4th Semester</option>
              <option value="5th">5th Semester</option>
              <option value="codingCourses">coding Notes</option>
              
            </select>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CascadingDropdown;
