import React from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { CgMail } from "react-icons/cg";
import insta from "../assets/Instagram.gif";
import youtube from "../assets/Youtube.gif";
import linkedIn from "../assets/LinkedIn.gif";

const Socialicon = () => {
  return (
    <div className='social_conatainer'>
      
      <div>
        <h3 className='social_h3'>Socials</h3>
        <p className='social_p'>Join our whatsapp group to get latest updates and to discuss about the project.</p>
        <div className='social_button'>
          <button className='social_button1'><FaWhatsapp /> WhatsApp</button>
          <button className='social_button2'><CgMail />Contact US</button>
        </div>
        </div>


        <p className='social_p1'> OR</p>
        <div className='Social_cards'>
          <div className='social_card'>
            <img className='insta_img' src={insta} alt='instagram' />
            <a href="https://www.instagram.com/snapedcodecampus/profilecard/?igsh=aWdxY2NjdmN0c2N1" target="_blank" rel="noopener noreferrer">
            <button className='join_button'>Follow Instagram</button>
            </a>
            
          </div>

          <div className='social_card'>
            <img className='insta_img' src={youtube} alt='YouTube' />
            <a href="https://youtube.com/@snapedcodecampus?si=yEwwseMw51I9PXVd" target="_blank" rel="noopener noreferrer">
            <button className='join_button' >Subscribe YouTube</button>
            </a>
            
          </div>
          <div className='social_card'>
            <img className='insta_img' src={linkedIn} alt='linkedIn' />
            <a href="https://www.linkedin.com/company/snaped-code-campus/" target="_blank" rel="noopener noreferrer">
            <button className='join_button' >Join LinkedIn</button>
            </a>
           
          </div>
        </div>


      </div>
  //  </div>
    // </div>
  )
}

export default Socialicon

