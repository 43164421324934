// import { doc, getDoc, updateDoc } from "firebase/firestore";
// import React, { useEffect, useState } from "react";
// import { toast, ToastContainer } from "react-toastify"; // Import Toastify
// import "react-toastify/dist/ReactToastify.css"; // Toastify CSS
// import { auth, db } from "../../components/firebase"; // Firebase config
// import { useNavigate } from "react-router-dom"; // React Router for navigation

// function Profile() {
//   const [userDetails, setUserDetails] = useState(null); // User details
//   const [loading, setLoading] = useState(true); // Loading state
//   const [file, setFile] = useState(null); // Selected file
//   const [uploading, setUploading] = useState(false); // Uploading state
//   const [showLogoutPopup, setShowLogoutPopup] = useState(false); // Logout popup

//   const navigate = useNavigate(); // For navigation

//   // Fetch user data from Firestore
//   const fetchUserData = async () => {
//     auth.onAuthStateChanged(async (user) => {
//       if (user) {
//         const docRef = doc(db, "Users", user.uid);
//         const docSnap = await getDoc(docRef);
//         if (docSnap.exists()) {
//           setUserDetails(docSnap.data());
//         } else {
//           console.log("No user data found");
//         }
//       } else {
//         console.log("User is not logged in");
//       }
//       setLoading(false);
//     });
//   };

//   // Effect to fetch user data on component mount
//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   // Logout handler
//   const handleLogout = async () => {
//     try {
//       await auth.signOut();
//       toast.success("Logout Successfully!");
//       navigate("/login"); // Redirect to login page
//     } catch (error) {
//       toast.error("Error logging out: " + error.message);
//     }
//   };

//   // File change handler
//   const handleFileChange = (e) => {
//     if (e.target.files[0]) {
//       setFile(e.target.files[0]);
//     }
//   };

//   // File upload handler
//   const handleUpload = async () => {
//     if (!file) {
//       toast.error("Please choose a file first!");
//       return;
//     }

//     try {
//       setUploading(true);

//       // Convert file to Base64
//       const reader = new FileReader();
//       reader.onload = async () => {
//         const base64Image = reader.result; // Base64 string

//         // Update Firestore document
//         const user = auth.currentUser;
//         if (user) {
//           const docRef = doc(db, "Users", user.uid);
//           await updateDoc(docRef, { photo: base64Image });

//           setUserDetails((prevDetails) => ({
//             ...prevDetails,
//             photo: base64Image,
//           }));

//           toast.success("Profile photo uploaded successfully!");
//         }
//       };

//       reader.onerror = () => {
//         toast.error("Error reading the file. Please try again.");
//       };

//       reader.readAsDataURL(file); // Convert file to Base64
//     } catch (error) {
//       console.error("Error uploading file:", error.message);
//       toast.error("Error uploading file: " + error.message);
//     } finally {
//       setUploading(false);
//     }
//   };

//   return (
//     <div className="profile-container">
//       <ToastContainer position="top-right" autoClose={3000} /> {/* Toast notifications */}

//       {loading ? (
//         <p>Loading...</p>
//       ) : userDetails ? (
//         <>
//           <div style={{ display: "flex", justifyContent: "center" }}>
//             {userDetails.photo ? (
//               <img
//                 alt="profileImage"
//                 src={userDetails.photo}
//                 className="profile-image"
//               />
//             ) : (
//               <p>No Profile Image</p>
//             )}
//           </div>

//           <h3>Welcome {userDetails.firstName} 🙏</h3>
//           <p>Email: {userDetails.email}</p>

//           <div className="upload-section">
//             <label htmlFor="file-input" className="choose-button">
//               Choose Image
//             </label>
//             <input
//               type="file"
//               id="file-input"
//               className="file-input"
//               onChange={handleFileChange}
//               accept="image/*"
//               style={{ display: "none" }} // Hide the default file input
//             />
//             <button
//               className="upload-button"
//               onClick={handleUpload}
//               disabled={uploading || !file}
//             >
//               {uploading ? "Uploading..." : "Upload Image"}
//             </button>
//           </div>

//           <button
//             className="logout-button"
//             onClick={() => setShowLogoutPopup(true)}
//           >
//             Logout
//           </button>

//           {showLogoutPopup && (
//             <div className="popup-overlay">
//               <div className="popup">
//                 <p>Are you sure you want to logout?</p>
//                 <button
//                   className="confirm-button"
//                   onClick={() => {
//                     setShowLogoutPopup(false);
//                     handleLogout();
//                   }}
//                 >
//                   Yes
//                 </button>
//                 <button
//                   className="cancel-button"
//                   onClick={() => setShowLogoutPopup(false)}
//                 >
//                   No
//                 </button>
//               </div>
//             </div>
//           )}
//         </>
//       ) : (
//         <p>No user data found</p>
//       )}
//     </div>
//   );
// }

// export default Profile;



import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css"; // Toastify CSS
import { auth, db } from "../../components/firebase"; // Firebase config
import { useNavigate } from "react-router-dom"; // React Router for navigation

function Profile() {
  const [userDetails, setUserDetails] = useState(null); // User details
  const [loading, setLoading] = useState(true); // Loading state
  const [file, setFile] = useState(null); // Selected file
  const [uploading, setUploading] = useState(false); // Uploading state
  const [showLogoutPopup, setShowLogoutPopup] = useState(false); // Logout popup state

  const navigate = useNavigate(); // For navigation

  // Fetch user data from Firestore
  const fetchUserData = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserDetails(docSnap.data());
        } else {
          console.log("No user data found");
        }
      } else {
        console.log("User is not logged in");
      }
      setLoading(false);
    });
  };

  // Effect to fetch user data on component mount
  useEffect(() => {
    fetchUserData();
  }, []);

  // Logout handler
  const handleLogout = async () => {
    try {
      await auth.signOut();
      toast.success("Logout Successfully!");
      navigate("/login"); // Redirect to login page
    } catch (error) {
      toast.error("Error logging out: " + error.message);
    }
  };

  // File change handler
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  // File upload handler
  const handleUpload = async () => {
    if (!file) {
      toast.error("Please choose a file first!");
      return;
    }

    try {
      setUploading(true);

      // Convert file to Base64
      const reader = new FileReader();
      reader.onload = async () => {
        const base64Image = reader.result; // Base64 string

        // Update Firestore document
        const user = auth.currentUser;
        if (user) {
          const docRef = doc(db, "Users", user.uid);
          await updateDoc(docRef, { photo: base64Image });

          setUserDetails((prevDetails) => ({
            ...prevDetails,
            photo: base64Image,
          }));

          toast.success("Profile photo uploaded successfully!");
        }
      };

      reader.onerror = () => {
        toast.error("Error reading the file. Please try again.");
      };

      reader.readAsDataURL(file); // Convert file to Base64
    } catch (error) {
      console.error("Error uploading file:", error.message);
      toast.error("Error uploading file: " + error.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="profile-container">
      <ToastContainer position="top-right" autoClose={3000} /> {/* Toast notifications */}

      {loading ? (
        <p>Loading...</p>
      ) : userDetails ? (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {userDetails.photo ? (
              <img
                alt="profileImage"
                src={userDetails.photo}
                className="profile-image"
              />
            ) : (
              <p>No Profile Image</p>
            )}
          </div>

          <h3>Welcome {userDetails.firstName} 🙏</h3>
          <p>Email: {userDetails.email}</p>

          <div className="upload-section">
            <label htmlFor="file-input" className="choose-button">
              Choose Image
            </label>
            <input
              type="file"
              id="file-input"
              className="file-input"
              onChange={handleFileChange}
              accept="image/*"
              style={{ display: "none" }} // Hide the default file input
            />
            <button
              className="upload-button"
              onClick={handleUpload}
              disabled={uploading || !file}
            >
              {uploading ? "Uploading..." : "Upload Image"}
            </button>
          </div>

          <button
            className="logout-button"
            onClick={() => setShowLogoutPopup(true)}
          >
            Logout
          </button>

          {showLogoutPopup && (
            <div className="popup-overlay">
              <div className="popup">
                <p>Are you sure you want to logout?</p>
                <button
                  className="confirm-button"
                  onClick={() => {
                    setShowLogoutPopup(false);
                    handleLogout();
                  }}
                >
                  Yes
                </button>
                <button
                  className="cancel-button"
                  onClick={() => setShowLogoutPopup(false)}
                >
                  No
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <p>No user data found</p>
      )}
    </div>
  );
}

export default Profile;
