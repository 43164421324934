// // import React, { useState } from "react";
// // import { signInWithEmailAndPassword } from "firebase/auth";
// // import { useNavigate } from "react-router-dom";
// // import { toast, ToastContainer } from "react-toastify";
// // import "react-toastify/dist/ReactToastify.css";
// // import demovideo from "../../assets/demo.mp4";
// // import logo from "../../assets/LOGO SnapED.png";
// // import { auth } from "../../components/firebase";
// // import SignInwithGoogle from "./signInWithGoogle";

// // function Login() {
// //   const [email, setEmail] = useState("");
// //   const [password, setPassword] = useState("");
// //   const navigate = useNavigate();

// //   const handleEmailLogin = async (e) => {
// //     e.preventDefault();
// //     try {
// //       await signInWithEmailAndPassword(auth, email, password);
// //       // Show custom success toast
// //       toast.success("🎉 Login Successful! Welcome to SnapED 🎓", {
// //         position: "top-center",
// //         autoClose: 1000, // Toast auto closes in 1 second
// //         hideProgressBar: false,
// //         closeOnClick: true,
// //         pauseOnHover: true,
// //         draggable: true,
// //         theme: "light",
// //       });
// //       // Add a 1-second delay before navigation
// //       setTimeout(() => {
// //         navigate("/");
// //       }, 1000);
// //     } catch (error) {
// //       // Show custom error toast
// //       toast.error(`❌ Login Failed: ${error.message}`, {
// //         position: "bottom-center",
// //         autoClose: 5000,
// //         hideProgressBar: false,
// //         closeOnClick: true,
// //         pauseOnHover: true,
// //         draggable: true,
// //         theme: "colored",
// //       });
// //     }
// //   };

// //   return (
// //     <>
// //       <div className="container">
// //         <div className="screen-section">
// //           {/* Background and Foreground smartphones */}
// //           <div className="smartphone_two">
// //             <video className="video" autoPlay loop muted>
// //               <source src={demovideo} type="video/mp4" />
// //               Your browser does not support the video tag.
// //             </video>
// //           </div>
// //           <div className="smartphone">
// //             <video className="video" autoPlay loop muted>
// //               <source src={demovideo} type="video/mp4" />
// //               Your browser does not support the video tag.
// //             </video>
// //           </div>
// //         </div>
// //         <div className="form-section">
// //           <form onSubmit={handleEmailLogin}>
// //             <img className="login-logo" src={logo} alt="SnapED codeCampus" />
// //             <h2>Login</h2>
// //             <div className="mb-3">
// //               <label>Email address</label>
// //               <input
// //                 type="email"
// //                 className="form-control"
// //                 placeholder="Enter email"
// //                 value={email}
// //                 onChange={(e) => setEmail(e.target.value)}
// //               />
// //             </div>
// //             <div className="mb-3">
// //               <label>Password</label>
// //               <input
// //                 type="password"
// //                 className="form-control"
// //                 placeholder="Enter password"
// //                 value={password}
// //                 onChange={(e) => setPassword(e.target.value)}
// //               />
// //             </div>
// //             <div className="d-grid">
// //               <button type="submit" className="btn btn-primary">
// //                 Submit
// //               </button>
// //             </div>
// //             <p className="forgot-password text-right">
// //               Don't have an account? <a href="/signup">Register Here</a>
// //             </p>
// //             <SignInwithGoogle />
// //           </form>
// //         </div>
// //       </div>
// //       {/* Toast Notification */}
// //       <ToastContainer
// //         position="top-center"
// //         autoClose={5000}
// //         hideProgressBar={false}
// //         newestOnTop={false}
// //         closeOnClick
// //         rtl={false}
// //         pauseOnFocusLoss
// //         draggable
// //         pauseOnHover
// //         theme="light"
// //       />
// //       <footer>
// //         <p>-- &copy; 2024 SnapED codeCampus. All rights reserved. --</p>
// //       </footer>
// //     </>
// //   );
// // }

// // export default Login;


// import React, { useState } from "react";
// import { signInWithEmailAndPassword } from "firebase/auth";
// import { useNavigate } from "react-router-dom";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import demovideo from "../../assets/demo.mp4";
// import logo from "../../assets/LOGO SnapED.png";
// import { auth } from "../../components/firebase";
// import SignInwithGoogle from "./signInWithGoogle";

// function Login() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const navigate = useNavigate();

//   const handleEmailLogin = async (e) => {
//     e.preventDefault();
//     try {
//       await signInWithEmailAndPassword(auth, email, password);
//       toast.success("🎉 Login Successful! Welcome to SnapED 🎓", {
//         position: "top-center",
//         autoClose: 1000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         theme: "light",
//       });
//       setTimeout(() => {
//         navigate("/");
//       }, 1000);
//     } catch (error) {
//       toast.error(`❌ Login Failed: ${error.message}`, {
//         position: "bottom-center",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         theme: "colored",
//       });
//     }
//   };

//   return (
//     <>
//       <div className="Auth_container">
//         <div className="Auth_screen-section">
//           <div className="Auth_smartphone_two">
//             <video className="Auth_video" autoPlay loop muted>
//               <source src={demovideo} type="video/mp4" />
//               Your browser does not support the video tag.
//             </video>
//           </div>
//           <div className="Auth_smartphone">
//             <video className="Auth_video" autoPlay loop muted>
//               <source src={demovideo} type="video/mp4" />
//               Your browser does not support the video tag.
//             </video>
//           </div>
//         </div>
//         <div className="Auth_form-section">
//           <form className="Auth_form" onSubmit={handleEmailLogin}>
//             <img
//               className="Auth_login-logo"
//               src={logo}
//               alt="SnapED codeCampus"
//             />
//             <h2 className="Auth_header">Login</h2>
//             <div className="Auth_mb-3">
//               <label>Email address</label>
//               <input
//                 type="email"
//                 className="Auth_form-control"
//                 placeholder="Enter email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//             </div>
//             <div className="Auth_mb-3">
//               <label>Password</label>
//               <input
//                 type="password"
//                 className="Auth_form-control"
//                 placeholder="Enter password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//             </div>
//             <div className="Auth_d-grid">
//               <button type="submit" className="Auth_btn-primary">
//                 Submit
//               </button>
//             </div>
//             <p className="Auth_forgot-password Auth_text-right">
//               Don't have an account? <a href="/signup">Register Here</a>
//             </p>
//             <SignInwithGoogle />
//           </form>
//         </div>
//       </div>
//       <ToastContainer />
//       <footer>
//         <p>-- &copy; 2024 SnapED codeCampus. All rights reserved. --</p>
//       </footer>
//     </>
//   );
// }

// export default Login;




import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import demovideo from "../../assets/demo.mp4";
import logo from "../../assets/LOGO SnapED.png";
import { auth } from "../../components/firebase";
import SignInwithGoogle from "./signInWithGoogle";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success("🎉 Login Successful! Welcome to SnapED 🎓", {
        position: "top-center",
        autoClose: 1000,
      });
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {
      toast.error(`❌ Login Failed: ${error.message}`, {
        position: "bottom-center",
        autoClose: 5000,
      });
    }
  };

  return (
    <>
      <div className="Auth_container">
        <div className="Auth_screen-section">
          <div className="Auth_smartphone_two">
            <video className="Auth_video" autoPlay loop muted>
              <source src={demovideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="Auth_smartphone">
            <video className="Auth_video" autoPlay loop muted>
              <source src={demovideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="Auth_form-section">
          <form className="Auth_form" onSubmit={handleEmailLogin}>
            <img
              className="Auth_login-logo"
              src={logo}
              alt="SnapED codeCampus"
            />
            <h2 className="Auth_header">Login</h2>
            <div className="Auth_mb-3">
              <label>Email address</label>
              <input
                type="email"
                className="Auth_form-control"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="Auth_mb-3">
              <label>Password</label>
              <input
                type="password"
                className="Auth_form-control"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="Auth_d-grid">
              <button type="submit" className="Auth_btn-primary">
                Submit
              </button>
            </div>
            <p className="Auth_forgot-password Auth_text-right">
              Don't have an account? <a href="/signup">Register Here</a>
            </p>
            <SignInwithGoogle />
          </form>
        </div>
      </div>
      <ToastContainer />
      <footer>
        <p>-- &copy; 2024 SnapED codeCampus. All rights reserved. --</p>
      </footer>
    </>
  );
}

export default Login;
