import React from 'react';
import Card from '../../../components/Card';

const Sem3 = ({ branch }) => {
  const notes = [
    {title: 'Computational Methods', link: '/notes/cse/Computational Methods'},
    {title: 'Digital Logic and Circuit Design', link: '/notes/cse/Digital Logic and Circuit Design'},
    {title: 'Discrete Mathematics', link: '/notes/cse/Discrete Mathematics'},
    {title: 'Data Structures', link: '/notes/cse/ Data Structures '},
    {title: 'Indian Knowledge Sysytem', link: '/notes/cse/Indian Knowledge System'},
    {title: 'Object Oriented Programming', link: '/notes/cse/Object Oriented Programming'}
    

  ];
  return (
    <>
      <section className='sem'>
        <h1>CSE-IIIrd Semester Notes</h1>
      </section>
      <section className='cards-container'>
        {notes.map((note, index) =>
        <Card key={index} title={note.title} link={note.link}/>
        )}
      </section>
    </>
  )
}

export default Sem3;
