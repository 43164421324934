
import CascadingDropdown from './components/CascadingDropdown';
import React, { useEffect, useState } from 'react';
import EventComponent from './components/Event';
import Socialicon from './components/Socialicon';
import Footer from './components/Footer';

const Home = () => {
    const [textIndex, setTextIndex] = useState(0);
    const texts = [
      "Notes",
      "Important Questions",
      "Important Topics",
      "PYQ's",
      "Video Lecture Playlist"
    ];
  
    useEffect(() => {
      const interval = setInterval(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }, 2000); // Change text every 2 seconds
  
      return () => clearInterval(interval);
    }, [texts.length]);
  return (
    <div className="home-container">

    <h1 className="animated-tagline">Welcome to SnapED codeCampus</h1>
    <h2 className="animated-subtext">
      Providing <span className="animated-text">{texts[textIndex]}</span>
    </h2>
    <CascadingDropdown/>
    <EventComponent/>
    <Socialicon/>
    <Footer/>
    <div className='morph-background'>
          <div className='morph'></div>
          </div>
    </div>
  )
}

export default Home;