import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaBars,
  FaTimes,
  FaHome,
  FaInfoCircle,
  FaPhoneAlt,
  FaUser,
  FaFileAlt,
  FaHandsHelping,
} from "react-icons/fa";

const ContributeButton = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: 9999,
      }}
    >
      {/* Floating Options */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginBottom: menuOpen ? "10px" : "0px",
          opacity: menuOpen ? 1 : 0,
          transform: menuOpen ? "scale(1)" : "scale(0.8)",
          transition: "all 0.3s ease",
          pointerEvents: menuOpen ? "auto" : "none",
        }}
      >
        {menuItems.map((item, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onMouseEnter={() => setHoveredItem(index)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            {/* Tooltip */}
            {hoveredItem === index && (
              <div
                style={{
                  position: "absolute",
                  bottom: "60px",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                  zIndex: 10,
                }}
              >
                {item.label}
              </div>
            )}
            {item.isExternal ? (
              <button
                onClick={() => {
                  item.action();
                  closeMenu();
                }}
                style={{
                  ...menuButtonStyle,
                  transform: hoveredItem === index ? "scale(1.2)" : "scale(1)",
                  transition: "transform 0.2s ease, background-color 0.3s ease",
                }}
                aria-label={item.label}
              >
                {item.icon}
              </button>
            ) : (
              <Link
                to={item.link}
                style={{
                  textDecoration: "none",
                }}
                onClick={closeMenu}
              >
                <button
                  style={{
                    ...menuButtonStyle,
                    transform: hoveredItem === index ? "scale(1.2)" : "scale(1)",
                    transition: "transform 0.2s ease, background-color 0.3s ease",
                  }}
                  aria-label={item.label}
                >
                  {item.icon}
                </button>
              </Link>
            )}
          </div>
        ))}
      </div>

      {/* Main Button */}
      <button
        onClick={toggleMenu}
        style={{
          ...mainButtonStyle,
          ...(menuOpen ? hoverEffectStyle : {}),
        }}
        aria-label="Menu"
      >
        {menuOpen ? <FaTimes size={32} /> : <FaBars size={32} />}
      </button>
    </div>
  );
};

const menuItems = [
  { label: "Home", link: "/", icon: <FaHome size={32} /> },
  { label: "About", link: "/about", icon: <FaInfoCircle size={32} /> },
  { label: "Contact", link: "/contactus", icon: <FaPhoneAlt size={32} /> },
  { label: "Profile", link: "/profile", icon: <FaUser size={32} /> },
  { label: "Event", link: "/event", icon: <FaFileAlt size={32} /> },
  {
    label: "Contribute",
    isExternal: true,
    action: () => window.open("https://forms.gle/ufk6ibsZdRkhxuTc8", "_blank"),
    icon: <FaHandsHelping size={32} />,
  },
];

const mainButtonStyle = {
  width: "70px", // Slightly larger for main button
  height: "70px",
  borderRadius: "50%",
  backgroundColor: "#4CAF50",
  color: "white",
  border: "none",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "background-color 0.3s ease, transform 0.2s ease",
};

const menuButtonStyle = {
  width: "60px", // Button size matches icon
  height: "60px",
  borderRadius: "50%",
  color: "white",
  border: "none",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#03346e",
  padding: "0", // No extra padding
};

const hoverEffectStyle = {
  backgroundColor: "#FF4D4D",
  transform: "rotate(90deg)",
};

export default ContributeButton;
