// // PSLA
// import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// import {
//   addDoc,
//   collection,
//   deleteDoc,
//   doc,
//   getDocs,
// } from "firebase/firestore";
// import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
// import React, { useEffect, useState } from "react";
// import Accordions from "../../../components/Dropdowns";
// import { auth, db, storage } from "../../../components/firebase";
// import Card from "../../../components/notescard";
// import Tabs from "../../../components/Tabs";

// const PSLA = () => {
//   const [user, setUser] = useState(null);
//   const [apnotes, setApNotes] = useState([]);
//   const adminEmail = "snaped4338@gmail.com";

//   useEffect(() => {
//     const fetchNotesFromFirestore = async () => {
//       const notesCollection = collection(db, "PSLA_Notes");
//       const notesSnapshot = await getDocs(notesCollection);
//       const notesList = notesSnapshot.docs.map((doc) => ({
//         ...doc.data(),
//         id: doc.id,
//       }));
//       setApNotes(notesList);
//     };
//     fetchNotesFromFirestore();
//   }, []);

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       setUser(user);
//     });
//     return () => unsubscribe();
//   }, []);

//   const handleGoogleLogin = async () => {
//     const provider = new GoogleAuthProvider();
//     try {
//       const result = await signInWithPopup(auth, provider);
//       setUser(result.user);
//     } catch (error) {
//       console.error("Error during Google login:", error);
//     }
//   };

//   const handleFileUpload = (e, category) => {
//     const file = e.target.files[0];
//     const storageRef = ref(storage, `notes/${category}/${file.name}`);
//     uploadBytes(storageRef, file).then((snapshot) => {
//       getDownloadURL(snapshot.ref).then((url) => {
//         const newNote = { title: file.name, link: url, category };
//         addDoc(collection(db, "PSLA_Notes"), newNote).then(() => {
//           setApNotes((prevNotes) => [...prevNotes, newNote]);
//         });
//       });
//     });
//   };

//   const handleDeleteNote = async (noteToDelete) => {
//     await deleteDoc(doc(db, "PSLA_Notes", noteToDelete.id));
//     setApNotes((prevNotes) =>
//       prevNotes.filter((note) => note.id !== noteToDelete.id)
//     );
//   };

//   const renderUploadButton = (category) => (
//     <div className="upload-container">
//       {user?.email === adminEmail && (
//         <input
//           type="file"
//           accept="application/pdf"
//           onChange={(e) => handleFileUpload(e, category)}
//         />
//       )}
//     </div>
//   );

//   const renderCards = (category) => (
//     <div className="cards-container">
//       {apnotes
//         .filter((note) => note.category === category)
//         .map((note, index) => (
//           <Card
//             key={index}
//             title={note.title}
//             link={note.link}
//             onDelete={() => handleDeleteNote(note)}
//             showDelete={user?.email === adminEmail}
//           />
//         ))}
//     </div>
//   );

//   const accordionContent = [
//     <div key="1">
//       <p>Basics: Probability and Statistical models, Sample Spaces and Events, Counting Techniques, Interpretations and Axioms of Probability, Unions of Events and Addition Rules, Conditional Probability, Intersections of Events and Multiplication and Total Probability Rules, Independence, Bayes’ Theorem, Random Variables.</p>
//     </div>,
//     <div key="2">
//       <p>Joint Probability Distributions for Two Random Variables, Conditional Probability Distributions and Independence, Joint Probability Distributions for Two Random Variables, Covariance and Correlation, Common Joint Distributions, Linear Functions of RandomVariables, General Functions of Random Variables, Moment-Generating Functions.</p>
//     </div>,
//         <div key="3">
//         <p>Hypotheses Testing for a SingleSample: Tests on the Mean of a Normal Distribution with Variance Known / Unknown, Tests on the Variance and Standard Deviationof a Normal Distribution, Tests on a Population Proportion, Testing for Goodness of Fit, Nonparametric tests (Signed, Wilcoxon), Similarly Statistical Inference forTwo Samples. Regression and Correlation: Linear Regression, Least Squares Estimators, Hypotheses testing for simple linear regression, Confidence Intervals, Adequacy of model, Correlation, Transformed Variables, Logistic Regression. Similarly, for multiple linear regression including aspects of MLR.
//         </p>
//       </div>,
//       <div key="4">
//         <p>Linear Programming: Introduction, formulation of problem, Graphical method, Canonical and Standard form of LPP, Simplex method, Duality concept, Dual simplex method, Transportation and Assignment problem.</p>
//       </div>,
//   ];

//   const youtube = [
//     {
//       // unit: "Complete Unit",
//       // playlist: "https://youtube.com/playlist?list=PLxCzCOWd7aiFjZP4z1_3Kr3m3J5wu5Q8V&si=Hq14SL6t1smxXV-V",
//     },
//   ];

//   const tabData = [
//     {
//       id: "syllabus",
//       label: "Syllabus",
//       content: <Accordions accordionContent={accordionContent} />,
//     },
//     {
//       id: "notes",
//       label: "Notes",
//       content: (
//         <>
//           {renderUploadButton("notes")}
//           {renderCards("notes")}
//         </>
//       ),
//     },
//     {
//       id: "pyqs",
//       label: "Previous Year Questions",
//       content: (
//         <>
//           {renderUploadButton("pyqs")}
//           {renderCards("pyqs")}
//         </>
//       ),
//     },
//     {
//       id: "important",
//       label: "Important Questions",
//       content: (
//         <>
//           {renderUploadButton("important")}
//           {renderCards("important")}
//         </>
//       ),
//     },
//     {
//       id: "books",
//       label: "Books",
//       content: (
//         <>
//           {renderUploadButton("books")}
//           {renderCards("books")}
//         </>
//       ),
//     },
//     {
//       id: "youtube_playlist",
//       label: "Youtube Playlist",
//       content: (
//         <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
//           {youtube.map((unit, index) => (
//             <div  key={index}>
//               <h3>{unit.unit}</h3>
//               <a  href={unit.playlist} target="_blank" rel="noopener noreferrer">
//               {unit.unit}
//               </a>
//             </div>
//           ))}
//         </div>
//       ),
//     }
    
//   ];

//   return (
//     <section className="Ap_I">
//       <h1> Probability, Statistics and Linear Algebra </h1>
//       {!user ? (
//         <button onClick={handleGoogleLogin}>Login with Google</button>
//       ) : (
//         <div>
//           <p>Welcome, {user.displayName}! </p>
//         </div>
//       )}
//       <Tabs tabData={tabData} />
//     </section>
//   );
// };

// export default PSLA;


import React, { useState, useEffect } from 'react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { addDoc, collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { auth, db } from '../../../components/firebase';
import Tabs from '../../../components/Tabs';
import Card from '../../../components/notescard';
import Accordions from '../../../components/Dropdowns';

const PSLA = () => {
  const [user, setUser] = useState(null);
  const [apNotes, setApNotes] = useState([]);
  const [videos, setVideos] = useState([]); // State for videos
  const [videoTitle, setVideoTitle] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [driveLink, setDriveLink] = useState(''); // Google Drive link for file
  const [driveTitle, setDriveTitle] = useState(''); // Title for Google Drive link
  const adminEmail = 'snaped4338@gmail.com';

  // Fetch notes from Firestore
  useEffect(() => {
    const fetchNotesFromFirestore = async () => {
      const notesSnapshot = await getDocs(collection(db, 'PSLANotes'));
      setApNotes(notesSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    fetchNotesFromFirestore();
  }, []);

  // Fetch videos from Firestore
  useEffect(() => {
    const fetchVideos = async () => {
      const videoCollection = collection(db, 'PSLAVideos');
      const videoSnapshot = await getDocs(videoCollection);
      setVideos(videoSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    fetchVideos();
  }, []);

  // Monitor user authentication state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  // Google Sign-In
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  // File upload handler (using Firestore for Google Drive link)
  const handleDriveLinkUpload = (category) => {
    if (!driveLink || !driveTitle) {
      alert('Please provide both a valid title and Google Drive link.');
      return;
    }

    const newNote = { title: driveTitle, category, link: driveLink };
    addDoc(collection(db, 'PSLANotes'), newNote).then(() => {
      setApNotes((prevNotes) => [...prevNotes, newNote]);
      alert('Google Drive link uploaded successfully!');
      setDriveLink(''); // Clear the input after uploading
      setDriveTitle(''); // Clear the title input after uploading
    });
  };

  // Handle deleting a note
  const handleDeleteNote = async (note) => {
    const noteDoc = doc(db, 'PSLANotes', note.id);
    await deleteDoc(noteDoc);
    setApNotes(apNotes.filter((n) => n.id !== note.id)); // Remove from state
  };

  // Handle adding a video
  const handleAddVideo = async () => {
    if (!videoTitle || !videoUrl) {
      alert('Please provide both a valid title and video URL.');
      return;
    }

    const newVideo = { title: videoTitle, link: videoUrl };
    const videoRef = await addDoc(collection(db, 'PSLAVideos'), newVideo);
    setVideos((prevVideos) => [...prevVideos, { id: videoRef.id, ...newVideo }]);
    setVideoTitle(''); // Clear title input after adding video
    setVideoUrl(''); // Clear URL input after adding video
  };

  // Handle deleting a video
  const handleDeleteVideo = async (videoId) => {
    const videoDoc = doc(db, 'PSLAVideos', videoId);
    await deleteDoc(videoDoc);
    setVideos(videos.filter((video) => video.id !== videoId)); // Remove from state
  };

  // Render upload button with Google Drive link input
  const renderUploadButton = (category) => (
    <div className="upload-container">
      {user?.email === adminEmail && (
        <div>
          <input
            type="text"
            placeholder="Note Title"
            value={driveTitle}
            onChange={(e) => setDriveTitle(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <input
            type="text"
            placeholder="Google Drive Link"
            value={driveLink}
            onChange={(e) => setDriveLink(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <button onClick={() => handleDriveLinkUpload(category)}>Upload Link</button>
        </div>
      )}
    </div>
  );

  // Render cards with iframe for displaying PDFs from Google Drive links
  const renderCards = (category) => (
    <div className="cards-container">
      {apNotes
        .filter((note) => note.category === category)
        .map((note, index) => (
          <Card
            key={index}
            title={note.title}
            link={note.link}
            onDelete={() => handleDeleteNote(note)}
            showDelete={user?.email === adminEmail}
            user={user}
          >
            {/* Display PDF using iframe for Google Drive links */}
            {note.link && (
              <iframe
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${note.link}`}
                width="100%"
                height="500px"
                frameBorder="0"
                title={note.title}
              />
            )}
          </Card>
        ))}
    </div>
  );

  const renderVideoSection = () => (
    <div>
      {user?.email === adminEmail && (
        <div style={{ marginBottom: '20px' }}>
          <input
            type="text"
            placeholder="Video Title"
            value={videoTitle}
            onChange={(e) => setVideoTitle(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <input
            type="text"
            placeholder="YouTube Video or Playlist URL"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <button onClick={handleAddVideo}>Add Video</button>
        </div>
      )}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        {videos.map((video) => (
          <div
            key={video.id}
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              borderRadius: '8px',
            }}
          >
            <h5>{video.title}</h5>
            <iframe
              src={video.link}
              title={video.title}
              width="300"
              height="200"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            {user?.email === adminEmail && (
              <button
                onClick={() => handleDeleteVideo(video.id)}
                style={{ marginTop: '10px' }}
              >
                Delete
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );


  const accordionContent = [
    <div key="1">
      <p>Basics: Probability and Statistical models, Sample Spaces and Events, Counting Techniques, Interpretations and Axioms of Probability, Unions of Events and Addition Rules, Conditional Probability, Intersections of Events and Multiplication and Total Probability Rules, Independence, Bayes’ Theorem, Random Variables.</p>
    </div>,
    <div key="2">
      <p>Joint Probability Distributions for Two Random Variables, Conditional Probability Distributions and Independence, Joint Probability Distributions for Two Random Variables, Covariance and Correlation, Common Joint Distributions, Linear Functions of RandomVariables, General Functions of Random Variables, Moment-Generating Functions.</p>
    </div>,
        <div key="3">
        <p>Hypotheses Testing for a SingleSample: Tests on the Mean of a Normal Distribution with Variance Known / Unknown, Tests on the Variance and Standard Deviationof a Normal Distribution, Tests on a Population Proportion, Testing for Goodness of Fit, Nonparametric tests (Signed, Wilcoxon), Similarly Statistical Inference forTwo Samples. Regression and Correlation: Linear Regression, Least Squares Estimators, Hypotheses testing for simple linear regression, Confidence Intervals, Adequacy of model, Correlation, Transformed Variables, Logistic Regression. Similarly, for multiple linear regression including aspects of MLR.
        </p>
      </div>,
      <div key="4">
        <p>Linear Programming: Introduction, formulation of problem, Graphical method, Canonical and Standard form of LPP, Simplex method, Duality concept, Dual simplex method, Transportation and Assignment problem.</p>
      </div>,
  ];

  const tabData = [
    {
      id: 'syllabus',
      label: 'Syllabus',
      content: <Accordions accordionContent={accordionContent} />,
    },
    {
      id: 'notes',
      label: 'Notes',
      content: (
        <>
          {renderUploadButton('notes')}
          {renderCards('notes')}
        </>
      ),
    },
    {
      id: 'important',
      label: 'Important',
      content: (
        <>
          {renderUploadButton('important')}
          {renderCards('important')}
        </>
      ),
    },
    {
      id: 'books',
      label: 'Books',
      content: (
        <>
          {renderUploadButton('books')}
          {renderCards('books')}
        </>
      ),
    },

    {
      id: 'PYQ',
      label: 'PYQ',
      content: (
        <>
          {renderUploadButton('pyq')}
          {renderCards('pyq')}
        </>
      ),
    },
    {
      id: 'youtube_playlist',
      label: 'YouTube Videos',
      content: renderVideoSection(),
    },
  ];

  return (
    <section>
      <h1 className='Subjectname'>Probability, Statistics and Linear Algebra</h1>
      {!user ? (
        <button onClick={handleGoogleLogin}>Login with Google</button>
      ) : (
        <Tabs tabData={tabData} />
      )}
    </section>
  );
};

export default PSLA;


