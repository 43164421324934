import React from 'react'

const Footer = () => {
  return (
    <footer>
    <p>-- &copy; 2024 SnapED codeCampus. All rights reserved. --</p>
  </footer>
  )
}

export default Footer