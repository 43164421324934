import React from 'react';
import Card from '../../../components/Card';

const Sem4 = ({ branch }) => {
  const notes = [
    {title: 'Circuits and Systems', link: '/notes/cse/CircuitsandSystems'},
    {title: 'Database Management System', link: '/notes/cse/DBMS'},
    {title: 'Programming in java', link: '/notes/cse/Java'},
    {title: 'Probability Statistics and Linear Programming', link: '/notes/cse/PSLP'},
    {title: 'Theory of Computation', link: '/notes/cse/Theory of Computation'},
    {title: 'Technical Writing', link: '/physics'}
    

  ];
  return (
    <>
      <section className='sem'>
        <h1>IT-IVth Semester Notes</h1>
      </section>
      <section className='cards-container'>
        {notes.map((note, index) =>
        <Card key={index} title={note.title} link={note.link}/>
        )}
      </section>
    </>
  )
}

export default Sem4;
