import React, { useState, useEffect } from 'react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { addDoc, collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { auth, db } from '../../../components/firebase';
import Tabs from '../../../components/Tabs';
import Card from '../../../components/notescard';
import Accordions from '../../../components/Dropdowns';

const AppliedPhysicsI = () => {
  const [user, setUser] = useState(null);
  const [apNotes, setApNotes] = useState([]);
  const [videos, setVideos] = useState([]); // State for videos
  const [videoTitle, setVideoTitle] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [driveLink, setDriveLink] = useState(''); // Google Drive link for file
  const [driveTitle, setDriveTitle] = useState(''); // Title for Google Drive link
  const adminEmail = 'snaped4338@gmail.com';

  // Fetch notes from Firestore
  useEffect(() => {
    const fetchNotesFromFirestore = async () => {
      const notesSnapshot = await getDocs(collection(db, 'AppliedPhysicsINotes'));
      setApNotes(notesSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    fetchNotesFromFirestore();
  }, []);

  // Fetch videos from Firestore
  useEffect(() => {
    const fetchVideos = async () => {
      const videoCollection = collection(db, 'AppliedPhysicsIVideos');
      const videoSnapshot = await getDocs(videoCollection);
      setVideos(videoSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    fetchVideos();
  }, []);

  // Monitor user authentication state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  // Google Sign-In
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  // File upload handler (using Firestore for Google Drive link)
  const handleDriveLinkUpload = (category) => {
    if (!driveLink || !driveTitle) {
      alert('Please provide both a valid title and Google Drive link.');
      return;
    }

    const newNote = { title: driveTitle, category, link: driveLink };
    addDoc(collection(db, 'AppliedPhysicsINotes'), newNote).then(() => {
      setApNotes((prevNotes) => [...prevNotes, newNote]);
      alert('Google Drive link uploaded successfully!');
      setDriveLink(''); // Clear the input after uploading
      setDriveTitle(''); // Clear the title input after uploading
    });
  };

  // Handle deleting a note
  const handleDeleteNote = async (note) => {
    const noteDoc = doc(db, 'AppliedPhysicsINotes', note.id);
    await deleteDoc(noteDoc);
    setApNotes(apNotes.filter((n) => n.id !== note.id)); // Remove from state
  };

  // Handle adding a video
  const handleAddVideo = async () => {
    if (!videoTitle || !videoUrl) {
      alert('Please provide both a valid title and video URL.');
      return;
    }

    const newVideo = { title: videoTitle, link: videoUrl };
    const videoRef = await addDoc(collection(db, 'AppliedPhysicsIVideos'), newVideo);
    setVideos((prevVideos) => [...prevVideos, { id: videoRef.id, ...newVideo }]);
    setVideoTitle(''); // Clear title input after adding video
    setVideoUrl(''); // Clear URL input after adding video
  };

  // Handle deleting a video
  const handleDeleteVideo = async (videoId) => {
    const videoDoc = doc(db, 'AppliedPhysicsIVideos', videoId);
    await deleteDoc(videoDoc);
    setVideos(videos.filter((video) => video.id !== videoId)); // Remove from state
  };

  // Render upload button with Google Drive link input
  const renderUploadButton = (category) => (
    <div className="upload-container">
      {user?.email === adminEmail && (
        <div>
          <input
            type="text"
            placeholder="Note Title"
            value={driveTitle}
            onChange={(e) => setDriveTitle(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <input
            type="text"
            placeholder="Google Drive Link"
            value={driveLink}
            onChange={(e) => setDriveLink(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <button onClick={() => handleDriveLinkUpload(category)}>Upload Link</button>
        </div>
      )}
    </div>
  );

  // Render cards with iframe for displaying PDFs from Google Drive links
  const renderCards = (category) => (
    <div className="cards-container">
      {apNotes
        .filter((note) => note.category === category)
        .map((note, index) => (
          <Card
            key={index}
            title={note.title}
            link={note.link}
            onDelete={() => handleDeleteNote(note)}
            showDelete={user?.email === adminEmail}
            user={user}
          >
            {/* Display PDF using iframe for Google Drive links */}
            {note.link && (
              <iframe
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${note.link}`}
                width="100%"
                height="500px"
                frameBorder="0"
                title={note.title}
              />
            )}
          </Card>
        ))}
    </div>
  );

  const renderVideoSection = () => (
    <div>
      {user?.email === adminEmail && (
        <div style={{ marginBottom: '20px' }}>
          <input
            type="text"
            placeholder="Video Title"
            value={videoTitle}
            onChange={(e) => setVideoTitle(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <input
            type="text"
            placeholder="YouTube Video or Playlist URL"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <button onClick={handleAddVideo}>Add Video</button>
        </div>
      )}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        {videos.map((video) => (
          <div
            key={video.id}
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              borderRadius: '8px',
            }}
          >
            <h5>{video.title}</h5>
            <iframe
              src={video.link}
              title={video.title}
              width="300"
              height="200"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            {user?.email === adminEmail && (
              <button
                onClick={() => handleDeleteVideo(video.id)}
                style={{ marginTop: '10px' }}
              >
                Delete
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );


  const accordionContent = [
    <div key="1">
      <p>Introduction to Thermodynamics: Fundamental Ideas of Thermodynamics, The Continuum Model, The Concept of a "System", "State", "Equilibrium", "Process". Equations of state, Heat, Zeroth Law of Thermodynamics, Work, first and second laws of thermodynamics, entropy.</p>
    </div>,
    <div key="2">
      <p>Waves and Oscillations: Wave motion, simple harmonic motion, wave equation, superposition principle. Introduction to Electromagnetic Theory: Maxwell’s equations. work done by the electromagnetic field, Poynting’s theorem, Momentum, Angular momentum in electromagnetic fields, Electromagnetic waves: the wave equation, plane electromagnetic waves, energy carried by electromagnetic waves.</p>
    </div>,
    <div key="2">
      <p>Interference: Interference by division of wave front (Young's double slit experiment, Fresnel's biprism), interference by division of amplitude (thin films, Newton's rings, Michelson's interferometer), Coherence and coherent sources, Diffraction: Fraunhofer and Fresnel diffraction; Fraunhofer diffraction for Single slit, double slit, and N-slit (diffraction grating), Fraunhofer diffraction from a circular aperture, resolving power and dispersive power of a grating, Rayleigh criterion, resolving power of optical instruments, Polarization: Introduction to polarization, Brewster’s law, Malu's law, Nicol prism, double refraction, quarter-wave and half-wave plates, optical activity, specific rotation, Laurent half shade polarimeter.</p>
    </div>,
    <div key="3">
      <p>Theory of relativity: The Michelson-Morley Experiment and the speed of light; Absolute and Inertial frames of reference, Galilean transformations, the postulates of the special theory of relativity, Lorentz transformations, time dilation, length contraction, velocity addition, mass energy equivalence. Invariance of Maxwell’s equations under Lorentz Transformation. Introduction to Laser Physics: Introduction, coherence, Einstein A and B coefficients, population inversion, basic principle and operation of a laser, the He-Ne laser and the Ruby laser.</p>
    </div>
  ];

  const tabData = [
    {
      id: 'syllabus',
      label: 'Syllabus',
      content: <Accordions accordionContent={accordionContent} />,
    },
    {
      id: 'notes',
      label: 'Notes',
      content: (
        <>
          {renderUploadButton('notes')}
          {renderCards('notes')}
        </>
      ),
    },
    {
      id: 'important',
      label: 'Important',
      content: (
        <>
          {renderUploadButton('important')}
          {renderCards('important')}
        </>
      ),
    },
    {
      id: 'books',
      label: 'Books',
      content: (
        <>
          {renderUploadButton('books')}
          {renderCards('books')}
        </>
      ),
    },

    {
      id: 'PYQ',
      label: 'PYQ',
      content: (
        <>
          {renderUploadButton('pyq')}
          {renderCards('pyq')}
        </>
      ),
    },
    {
      id: 'youtube_playlist',
      label: 'YouTube Videos',
      content: renderVideoSection(),
    },
  ];

  return (
    <section>
      <h1 className='Subjectname'>Applied Physics-I</h1>
      {!user ? (
        <button onClick={handleGoogleLogin}>Login with Google</button>
      ) : (
        <Tabs tabData={tabData} />
      )}
    </section>
  );
};

export default AppliedPhysicsI;


