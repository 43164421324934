import {
  faEnvelope,
  faFileAlt,
  faHome,
  faInfoCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import logo from "../assets/LOGO SnapED.png";

const Navbar = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [logoActive, setLogoActive] = useState(false);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const handleLogoClick = () => {
    setLogoActive(true);
    setTimeout(() => setLogoActive(false), 300); // Reset the active state after animation
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Logo Section */}
        <div className="logo" onClick={handleLogoClick}>
          <Link to="/home">
            <motion.img
              src={logo}
              alt="logo"
              style={{ width: "150px", height: "auto" }}
              animate={{ scale: logoActive ? 1.2 : 1 }}
              transition={{ type: "spring", stiffness: 300 }}
            />
          </Link>
        </div>

        {/* Navigation Menu */}
        <ul className="nav-menu">
          {[
            { icon: faHome, label: "Home", path: "/" },
            { icon: faFileAlt, label: "Events", path: "/event" },
            { icon: faInfoCircle, label: "About", path: "/about" },
            { icon: faEnvelope, label: "Contact Us", path: "/contactus" },
            { icon: faUser, label: "Profile", path: "/profile" },
          ].map((item, index) => (
            <motion.li
              key={index}
              className={`nav-item ${activeIndex === index ? "active" : ""}`}
              onClick={() => handleClick(index)}
              initial={{ scale: 1 }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <Link to={item.path}>
                <FontAwesomeIcon icon={item.icon} /> {item.label}
              </Link>
            </motion.li>
          ))}
        </ul>

        {/* Contribute Button */}
        <div className="contribute-button">
          <motion.a
            href="https://forms.gle/ufk6ibsZdRkhxuTc8"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            Contribute
          </motion.a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
