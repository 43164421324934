// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Firebase Configuration (replace with your actual Firebase config)
const firebaseConfig = {
  apiKey: "AIzaSyABCLiL0HB_w2grKXW54nh49V_jBmyYLNM",
  authDomain: "snaped-dbe47.firebaseapp.com",
  projectId: "snaped-dbe47",
  storageBucket: "snaped-dbe47.firebasestorage.app",
  messagingSenderId: "705379377177",
  appId: "1:705379377177:web:508a11c248e5eb26040a88",
  measurementId: "G-V5KBE7KEH9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
