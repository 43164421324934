import React, { useState, useRef, useEffect } from "react";
import { FaTrashAlt, FaExpand, FaCompress } from "react-icons/fa";

const Notescard = ({ title, link, onDelete, showDelete, user }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const pdfViewerRef = useRef(null);

  const isBase64 = link.startsWith("data:application/pdf;base64,");

  const getGoogleDriveEmbedLink = (link) => {
    const fileIdMatch = link.match(/\/d\/([a-zA-Z0-9_-]+)\//);
    if (fileIdMatch) {
      const fileId = fileIdMatch[1];
      return `https://drive.google.com/file/d/${fileId}/preview`;
    }
    return link;
  };

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (pdfViewerRef.current) {
        pdfViewerRef.current.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  return (
    <div className="notescard">
      <div className="notescard-content">
        {title}
        <div className="button-container">
          {showDelete && (
            <button onClick={onDelete} className="delete-button">
              <FaTrashAlt /> Delete
            </button>
          )}
        </div>
      </div>

      {link && (
        <div
          ref={pdfViewerRef}
          className="pdf-view"
          style={{ position: "relative" }}
        >
          {isBase64 ? (
            <iframe
              src={link}
              width="100%"
              height={isFullscreen ? "100%" : "500px"}
              frameBorder="0"
              title={title}
            />
          ) : (
            <iframe
              src={getGoogleDriveEmbedLink(link)}
              width="100%"
              height={isFullscreen ? "100%" : "500px"}
              frameBorder="0"
              title={title}
            />
          )}
          <button
            onClick={toggleFullscreen}
            className="fullscreen-button"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 10,
              background: "black",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              padding: "5px",
            }}
          >
            {isFullscreen ? <FaCompress /> : <FaExpand />}
          </button>
        </div>
      )}
    </div>
  );
};

export default Notescard;
