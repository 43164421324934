import React, { useState, useEffect } from "react";
import { db, auth } from "./firebase"; 
import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css"; 
import { IoClose } from "react-icons/io5"; 

const EventComponent = () => {
  const [ , setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: "",
    description: "",
    formLink: "",
  });
  const [thumbnail, setThumbnail] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);
  // const [selectedEventId, setSelectedEventId] = useState(null); 
  const [showModal, setShowModal] = useState(false); 
  const [modalEvent, setModalEvent] = useState(null); 

  useEffect(() => {
    // Monitor authentication
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setIsAdmin(currentUser?.email === "snaped4338@gmail.com");
    });

    // Fetch events from Firestore
    const fetchEvents = async () => {
      const querySnapshot = await getDocs(collection(db, "events"));
      const fetchedEvents = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(fetchedEvents);
    };

    fetchEvents();
  }, []);

  // Handle the thumbnail image conversion to base64
  const handleThumbnailUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleAddEvent = async (e) => {
    e.preventDefault();
    const thumbnailURL = await handleThumbnailUpload(thumbnail);

    await addDoc(collection(db, "events"), {
      ...newEvent,
      thumbnail: thumbnailURL, // Store base64 image in Firestore
    });

    setNewEvent({ title: "", description: "", formLink: "" });
    setThumbnail(null);
    toast.success("Event added successfully!");
  };

  const handleEditEvent = async (e) => {
    e.preventDefault();
    const thumbnailURL = thumbnail ? await handleThumbnailUpload(thumbnail) : eventToEdit.thumbnail;

    await updateDoc(doc(db, "events", eventToEdit.id), {
      ...newEvent,
      thumbnail: thumbnailURL, // Update base64 image in Firestore
    });

    setEditMode(false);
    setEventToEdit(null);
    setNewEvent({ title: "", description: "", formLink: "" });
    setThumbnail(null);
    toast.success("Event updated successfully!");
  };

  const handleDeleteEvent = async (eventId) => {
    await deleteDoc(doc(db, "events", eventId));
    setEvents(events.filter((event) => event.id !== eventId));
    toast.error("Event deleted successfully!");
  };

  const handleLearnMore = (event) => {
    setModalEvent(event);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalEvent(null);
  };

  return (
    <div style={{ padding: "20px", maxWidth: "100%", margin: "0 auto" , backgroundColor: "#0A1F2E"}}>
      <h1 style={{ textAlign: "center", fontSize: "50px", fontFamily: "cursive", fontWeight: "bolder" }}>Events</h1>
      {isAdmin && (
        <div style={{ marginBottom: "30px" }}>
          <h2>{editMode ? "Edit Event" : "Add New Event"}</h2>
          <form onSubmit={editMode ? handleEditEvent : handleAddEvent}>
            <input
              type="text"
              placeholder="Title"
              value={newEvent.title}
              onChange={(e) =>
                setNewEvent({ ...newEvent, title: e.target.value })
              }
              style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
              required
            />
            <textarea
              placeholder="Description"
              value={newEvent.description}
              onChange={(e) =>
                setNewEvent({ ...newEvent, description: e.target.value })
              }
              style={{
                width: "100%",
                padding: "10px",
                marginBottom: "10px",
                height: "100px",
              }}
              required
            ></textarea>
            <input
              type="url"
              placeholder="Google Form Link"
              value={newEvent.formLink}
              onChange={(e) =>
                setNewEvent({ ...newEvent, formLink: e.target.value })
              }
              style={{
                width: "100%",
                padding: "10px",
                marginBottom: "10px",
              }}
              required
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setThumbnail(e.target.files[0])}
              style={{ marginBottom: "10px" }}
              required={!editMode}
            />
            <button
              type="submit"
              style={{
                padding: "10px 20px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                cursor: "pointer",
              }}
            >
              {editMode ? "Update Event" : "Add Event"}
            </button>
          </form>
        </div>
      )}

      {/* Horizontal scrolling section */}
      <div
        style={{
          display: "flex",
          overflowX: "auto",
          gap: "20px",
          padding: "10px 0",
          scrollBehavior: "smooth",
        }}
      >
        {events.map((event) => (
          <div
            key={event.id}
            style={{
              flex: "0 0 auto",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "20px",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              width: "250px", // Fixed width for consistency
              height: "350px", // Fixed height for consistency
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                height: "200px", // Fixed height for image container
                overflow: "hidden",
                borderRadius: "8px",
              }}
            >
              <img
                src={event.thumbnail}
                alt={event.title}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain", // Ensures the entire image is visible
                }}
              />
            </div>
            <h3 style={{ marginTop: "15px", fontSize: "18px", lineHeight: "1.2" }}>
              {event.title}
            </h3>
            <p style={{ fontSize: "14px", color: "#555", height: "50px", overflow: "hidden" }}>
              {event.description}
            </p>
            <a href={event.formLink} target="_blank" rel="noopener noreferrer">
              <button
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#008CBA",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  marginBottom: "10px",
                }}
              >
                Enroll
              </button>
            </a>
            <button
              onClick={() => handleLearnMore(event)}
              style={{
                padding: "10px 20px",
                backgroundColor: "#f39c12",
                color: "white",
                border: "none",
                cursor: "pointer",
              }}
            >
              Learn More
            </button>
            {isAdmin && (
              <div>
                <button
                  onClick={() => {
                    setEditMode(true);
                    setEventToEdit(event);
                    setNewEvent({
                      title: event.title,
                      description: event.description,
                      formLink: event.formLink,
                    });
                  }}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#f39c12",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteEvent(event.id)}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#e74c3c",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Modal */}
      {showModal && modalEvent && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#0056b3",
              padding: "20px",
              width: "60%",
              maxHeight: "80%",
              overflowY: "auto",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <IoClose
              onClick={handleCloseModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                fontSize: "24px",
              }}
            />
            <h2>{modalEvent.title}</h2>
            <p>{modalEvent.description}</p>
            <a href={modalEvent.formLink} target="_blank" rel="noopener noreferrer">
              <button
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#008CBA",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Enroll
              </button>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventComponent;
