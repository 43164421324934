import React, { useState } from 'react';


const Accordions = ({ accordionContent }) => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div className='accordions-container'>
      {accordionContent.map((content, index) => (
        <div key={index} className='accordion'>
          <div className='accordion-header'>
            <span className='accordion-title'>UNIT {index + 1}</span>
            <button
              className={`accordion-toggle ${activeAccordion === index ? 'active' : ''}`}
              onClick={() => handleAccordionClick(index)}
            >
              {activeAccordion === index ? '-' : '+'}
            </button>
          </div>
          {activeAccordion === index && (
            <div className='accordion-content'>
              {content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordions;
