import React, { useState } from "react";
import { db } from "./firebase"; // Ensure you have Firestore initialized here
import { collection, addDoc } from "firebase/firestore"; // Import Firestore functions
import { FaFacebook, FaLinkedin, FaYoutube, FaTwitter } from "react-icons/fa";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ContactUS = () => {
  const [formData, setFormData] = useState({
    message: "",
    email: "",
  });
  const [ , setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.message && formData.email) {
      try {
        // Add form data to Firestore collection 'contactResponses'
        await addDoc(collection(db, "contactResponses"), formData);
        setSubmitted(true);
        setFormData({ message: "", email: "" });
        toast.success("Thank you for sending a message!", { position: "top-right" });
      } catch (error) {
        console.error("Error submitting contact form: ", error);
        toast.error("Failed to send your message. Please try again.", { position: "top-right" });
      }
    }
  };

  return (
    <section className="contact_container" id="contactus">
      <ToastContainer />
      <motion.div
        className="Contact"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <h4 className="Contact_h4">Contact Us</h4>
        <motion.div
          className="contact_div"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          style={{
            marginLeft: 'auto', // Ensure the div is centered
            marginRight: 'auto', // Ensure the div is centered
            display: 'flex', // Use flexbox for centering
            flexDirection: 'column', // Align content vertically
          }}
        >
          <form onSubmit={handleSubmit}>
            <div className="contact_form">
              <p>Write some words</p>
              <motion.textarea
                className="contact_text"
                name="message"
                placeholder="Your words are important for our growth. Thank You."
                value={formData.message}
                onChange={handleChange}
                required
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 0.5 }}
              ></motion.textarea>
              <motion.input
                className="contact_input"
                type="email"
                name="email"
                placeholder="Enter your email."
                value={formData.email}
                onChange={handleChange}
                required
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.5 }}
              />
              <motion.button
                type="submit"
                className="contact_button"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.5 }}
              >
                Submit
              </motion.button>
              <h2>Find Us Here</h2>
              <div>
                <a href="https://facebook.com" className="contact_social-icons" target="_blank" rel="noopener noreferrer">
                  <FaFacebook />
                </a>
                <a href="https://linkedin.com" className="contact_social-icons" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin />
                </a>
                <a href="https://youtube.com" className="contact_social-icons" target="_blank" rel="noopener noreferrer">
                  <FaYoutube />
                </a>
                <a href="https://twitter.com" className="contact_social-icons" target="_blank" rel="noopener noreferrer">
                  <FaTwitter />
                </a>
              </div>
            </div>
          </form>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default ContactUS;
