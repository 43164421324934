import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'; // Import BrowserRouter
import './App.css';
import Home from './Home';
import AboutPage from './components/About';
import ContributeButton from './components/Contribute';
import Navbar from './components/Navbar';
import EventComponent from './components/Event';
import SignInwithGoogle from './pages/Auth/signInWithGoogle';
import Profile from './pages/Auth/Profile';
import Register from './pages/Auth/Signup';
import Login from './pages/Auth/Login';
import { auth } from './components/firebase'; // Import Firebase auth
import ContactUS from './components/ContactUS';
import Sem1 from './pages/btech/cse/Sem1';
import Sem2 from './pages/btech/cse/Sem2';
import Sem3 from './pages/btech/cse/Sem3';
import Sem4 from './pages/btech/cse/Sem4';
import Sem5 from './pages/btech/cse/Sem5';
import AimlSem2 from './pages/btech/aiml/Sem2';
import AimlSem3 from './pages/btech/aiml/Sem3';
import AimlSem4 from './pages/btech/aiml/Sem4';
import AidsSem2 from './pages/btech/aiml/Sem2';
import AidsSem3 from './pages/btech/aiml/Sem3';
import AidsSem4 from './pages/btech/aiml/Sem4';
import ItSem1 from './pages/btech/it/Sem1';
import ItSem2 from './pages/btech/it/Sem2';
import ItSem3 from './pages/btech/it/Sem3';
import ItSem4 from './pages/btech/it/Sem4';
import ItSem5 from './pages/btech/it/Sem5';
import AppliedChemistry from './pages/notes/cse/Applied Chemistry';
import AppliedMathsII from './pages/notes/cse/Applied_Maths_II';
import AppliedPhysicsI from './pages/notes/cse/AppliedPhysicsI';
import AppliedMathsI from './pages/notes/cse/AppliedMathsI';
import CS from './pages/notes/cse/Communication_Skills';
import EM from './pages/notes/cse/Engineering_Mechanics';
import ES from './pages/notes/cse/Electrical_Science';
import EVS from './pages/notes/cse/Enviromental_Science';
import HVE from './pages/notes/cse/HVE';
import IC from './pages/notes/cse/Indian_Constitution';
import MP from './pages/notes/cse/Manufacturing_Process';
import PC from './pages/notes/cse/Programming_In_C';
import CM from './pages/notes/cse/CM';
import DLCD from './pages/notes/cse/DLCD';
import DM from './pages/notes/cse/DM';
import DS from './pages/notes/cse/DS';
import IKS from './pages/notes/cse/IKS';
import OOPS from './pages/notes/cse/OOPS';
import CD from './pages/notes/cse/CD';
import CN from './pages/notes/cse/CN';
import DAA from './pages/notes/cse/DAA';
import Economics from './pages/notes/cse/Economics';
import OS from './pages/notes/cse/OS';
import SE from './pages/notes/cse/SE';
import AI from './pages/notes/aiml/AI';
import FODS from './pages/notes/aiml/Fods';
import CR from './pages/notes/aiml/CR';
import PSLA from './pages/notes/aiml/Psla';

// Higher-Order Component for Private Routes
const PrivateRoute = ({ element, isAuthenticated, ...rest }) => {
  return isAuthenticated ? (
    element
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // Check authentication state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user); // Set true if user is logged in
      setLoading(false); // Loading complete
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <Router>
      {isAuthenticated && <Navbar />} {/* Show Navbar only when authenticated */}
      {isAuthenticated && <ContributeButton />} {/* Show Contribute button only when authenticated */}

      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/signin" element={<SignInwithGoogle />} />

        {/* Private Routes */}
        <Route
          path="/"
          element={<PrivateRoute element={<Home />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/about"
          element={<PrivateRoute element={<AboutPage />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/event"
          element={<PrivateRoute element={<EventComponent />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/profile"
          element={<PrivateRoute element={<Profile />} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/contactus"
          element={<PrivateRoute element={<ContactUS/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/cse/1st"
          element={<PrivateRoute element={<Sem1 branch="CSE"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/cse/2nd"
          element={<PrivateRoute element={<Sem2 branch="CSE"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/cse/3rd"
          element={<PrivateRoute element={<Sem3 branch="CSE"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/cse/4th"
          element={<PrivateRoute element={<Sem4 branch="CSE"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/cse/5th"
          element={<PrivateRoute element={<Sem5 branch="CSE"/>} isAuthenticated={isAuthenticated} />}
        />    
        <Route
          path="/btech/aiml/1st"
          element={<PrivateRoute element={<Sem1 branch="AIML"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aiml/2nd"
          element={<PrivateRoute element={<AimlSem2 branch="AIML"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aiml/3rd"
          element={<PrivateRoute element={<AimlSem3 branch="AIML"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aiml/4th"
          element={<PrivateRoute element={<AimlSem4 branch="AIML"/>} isAuthenticated={isAuthenticated} />}
        />     
        <Route
          path="/btech/aids/1st"
          element={<PrivateRoute element={<Sem1 branch="AIDS"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aids/2nd"
          element={<PrivateRoute element={<AidsSem2 branch="AIDS"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aids/3rd"
          element={<PrivateRoute element={<AidsSem3 branch="AIDS"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/aids/4th"
          element={<PrivateRoute element={<AidsSem4 branch="AIDS"/>} isAuthenticated={isAuthenticated} />}
        />  
        <Route
          path="/btech/it/1st"
          element={<PrivateRoute element={<ItSem1 branch="CSE"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/it/2nd"
          element={<PrivateRoute element={<ItSem2 branch="CSE"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/it/3rd"
          element={<PrivateRoute element={<ItSem3 branch="CSE"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/it/4th"
          element={<PrivateRoute element={<ItSem4 branch="CSE"/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/btech/it/5th"
          element={<PrivateRoute element={<ItSem5 branch="CSE"/>} isAuthenticated={isAuthenticated} />}
        /> 
        <Route
          path="/notes/cse/AppliedChemistry"
          element={<PrivateRoute element={<AppliedChemistry/>} isAuthenticated={isAuthenticated} />}
        /> 
        <Route
          path="/notes/cse/AppliedMathII"
          element={<PrivateRoute element={<AppliedMathsII/>} isAuthenticated={isAuthenticated} />}
        /> 
        <Route
          path="/notes/cse/AppliedPhysicsI"
          element={<PrivateRoute element={<AppliedPhysicsI/>} isAuthenticated={isAuthenticated} />}
        /> 
        <Route
          path="/notes/cse/AppliedMathI"
          element={<PrivateRoute element={<AppliedMathsI/>} isAuthenticated={isAuthenticated} />}
        /> 
        <Route
          path="/notes/cse/CommunicationSkills"
          element={<PrivateRoute element={<CS/>} isAuthenticated={isAuthenticated} />}
        /> 
        <Route
          path="/notes/cse/Engineering_Mechanics"
          element={<PrivateRoute element={<EM/>} isAuthenticated={isAuthenticated} />}
        /> 
        <Route
          path="/notes/cse/Electrical_Science"
          element={<PrivateRoute element={<ES/>} isAuthenticated={isAuthenticated} />}
        /> 
        <Route
          path="/notes/cse/Enviromental_Studies"
          element={<PrivateRoute element={<EVS/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Human values and Ethics"
          element={<PrivateRoute element={<HVE/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Indian_Constitution"
          element={<PrivateRoute element={<IC/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Manufacturing_Process"
          element={<PrivateRoute element={<MP/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Programming_In_C"
          element={<PrivateRoute element={<PC/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Computational Methods"
          element={<PrivateRoute element={<CM/>} isAuthenticated={isAuthenticated} />}
        /> 
        <Route
          path="/notes/cse/Digital Logic and Circuit Design"
          element={<PrivateRoute element={<DLCD/>} isAuthenticated={isAuthenticated} />}
        /> 
        <Route
          path="/notes/cse/Discrete Mathematics"
          element={<PrivateRoute element={<DM/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/ Data Structures"
          element={<PrivateRoute element={<DS/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Indian Knowledge System"
          element={<PrivateRoute element={<IKS/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Object Oriented Programming"
          element={<PrivateRoute element={<OOPS/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/CD"
          element={<PrivateRoute element={<CD/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/CN"
          element={<PrivateRoute element={<CN/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/DAA"
          element={<PrivateRoute element={<DAA/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Economics"
          element={<PrivateRoute element={<Economics/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/OS"
          element={<PrivateRoute element={<OS/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/SE"
          element={<PrivateRoute element={<SE/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/AI"
          element={<PrivateRoute element={<AI/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Fods"
          element={<PrivateRoute element={<FODS/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/CR"
          element={<PrivateRoute element={<CR/>} isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/notes/cse/Psla"
          element={<PrivateRoute element={<PSLA/>} isAuthenticated={isAuthenticated} />}
        />                                             
                                                                                                                                               
      </Routes>
      
    </Router>

  );
};

export default App;
