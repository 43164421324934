import React from 'react';


const Card = ({title, link}) => {
  return (
    <>
    <a href={link} className='card'>
        <div className='card-content'>
            {title} 
        </div>
    </a>
    </>
      
    
  )
}

export default Card







