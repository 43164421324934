import React from 'react';
import Card from '../../../components/Card';

const Sem3 = ({ branch }) => {
  const notes = [
    {title: 'Foundation Of Data Science', link: '/notes/cse/Fods'},
    {title: 'Digital Logic and Circuit Design', link: '/notes/cse/Digital Logic and Circuit Design'},
    {title: 'Crystal Reasoning and System Thinking', link: '/notes/cse/CR'},
    {title: 'Data Structures', link: '/notes/cse/ Data Structures '},
    {title: 'Principles of Artificial Intelligence', link: '/notes/cse/AI'},
    {title: 'Probability, Statistics and Linear Algebra', link: '/notes/cse/Psla'}
    

  ];
  return (
    <>
      <section className='sem'>
        <h1>AIML-IIIrd Semester Notes</h1>
      </section>
      <section className='cards-container'>
        {notes.map((note, index) =>
        <Card key={index} title={note.title} link={note.link}/>
        )}
      </section>
    </>
  )
}

export default Sem3;
