import React from "react";
import { motion } from "framer-motion";


const AboutPage = () => {
  return (
    <div className="about-page">
      {/* Hero Section */}
      <section className="hero-section">
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="hero-content"
        >
          <h1>Welcome to SnapED codeCampus</h1>
          <p>Your Partner in Accessible and High-Quality Learning</p>
        </motion.div>
      </section>

      {/* Mission, Vision, Goals */}
      <section className="mvg-section">
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          Who We Are
        </motion.h2>
        <div className="timeline">
          <motion.div
            className="timeline-item"
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            <h3>Our Mission</h3>
            <p>
              Deliver accessible, affordable, and high-quality education for
              everyone while empowering learners with expert resources.
            </p>
          </motion.div>
          <motion.div
            className="timeline-item"
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.4 }}
          >
            <h3>Our Vision</h3>
            <p>
              Build an inclusive community of industry-ready professionals
              equipped with top-notch technical skills.
            </p>
          </motion.div>
          <motion.div
            className="timeline-item"
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.6 }}
          >
            <h3>Our Goals</h3>
            <p>
              Provide seamless educational experiences with curated notes,
              PYQs, and practical learning resources.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Services Section */}
      <section className="services-section">
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          What We Offer
        </motion.h2>
        <div className="services-cards">
          <motion.div
            className="service-card"
            whileHover={{ scale: 1.1 }}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h3>Expert Notes</h3>
            <p>Access expertly curated notes and important questions.</p>
          </motion.div>
          <motion.div
            className="service-card"
            whileHover={{ scale: 1.1 }}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <h3>Video Lectures</h3>
            <p>Learn effectively with topic-wise video lectures.</p>
          </motion.div>
          <motion.div
            className="service-card"
            whileHover={{ scale: 1.1 }}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <h3>Skill-Boosting Courses</h3>
            <p>Master DSA, Web Development, Python, Machine Learning, and more.</p>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
