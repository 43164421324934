// import React, { useState } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';

// const Tabs = ({ tabData }) => {
//   const [activeTab, setActiveTab] = useState(tabData[0]?.id);

//   const handleTabClick = (tabId) => {
//     setActiveTab(tabId);
//   };

//   return (
//     <div className="tabs-container">
//       {/* Tab header with animated highlight */}
//       <div className="tabs-header">
//         <div className="tabs-scroll" style={{ position: "relative" }}>
//           {tabData.map((tab) => (
//             <div
//               key={tab.id}
//               className={`tab ${activeTab === tab.id ? 'active' : ''}`}
//               onClick={() => handleTabClick(tab.id)}
//               style={{ position: "relative", padding: "10px 20px" }}
//             >
//               {tab.label}

//               {/* Animated highlight for active tab with scale and bounce */}
//               {activeTab === tab.id && (
//                 <motion.span
//                   layoutId="bubble"
//                   className="absolute inset-0 z-10"
//                   style={{
//                     borderRadius: '9999px',
//                     background: 'rgba(255, 255, 255, 0.1)',
//                     transform: 'scale(1)',
//                   }}
//                   initial={{ scale: 0.8, opacity: 0 }}
//                   animate={{ scale: 1, opacity: 1 }}
//                   exit={{ scale: 0.8, opacity: 0 }}
//                   transition={{ type: 'spring', stiffness: 300, damping: 20 }}
//                 />
//               )}
//             </div>
//           ))}
//         </div>
//       </div>

//       {/* Animated content transition */}
//       <div className="tabs-content">
//         <AnimatePresence mode="wait">
//           <motion.div
//             key={activeTab}
//             initial={{ opacity: 0, scale: 0.95, x: 50 }}
//             animate={{ opacity: 1, scale: 1, x: 0 }}
//             exit={{ opacity: 0, scale: 0.95, x: -50 }}
//             transition={{ duration: 0.4, ease: 'easeInOut' }}
//             style={{ borderRadius: '10px' }}
//           >
//             {tabData.find((tab) => tab.id === activeTab)?.content}
//           </motion.div>
//         </AnimatePresence>
//       </div>
//     </div>
//   );
// };

// export default Tabs;


import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Tabs = ({ tabData }) => {
  const [activeTab, setActiveTab] = useState(tabData[0]?.id);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="tabs-container">
      {/* Tab header with horizontal scrolling */}
      <div className="tabs-header">
        <div className="tabs-scroll">
          {tabData.map((tab) => (
            <div
              key={tab.id}
              className={`tab ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.label}

              {/* Animated highlight for active tab */}
              {activeTab === tab.id && (
                <motion.span
                  layoutId="bubble"
                  className="highlight"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.8, opacity: 0 }}
                  transition={{ type: 'spring', stiffness: 300, damping: 20 }}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Tab content with animation */}
      <div className="tabs-content">
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, scale: 0.95, x: 50 }}
            animate={{ opacity: 1, scale: 1, x: 0 }}
            exit={{ opacity: 0, scale: 0.95, x: -50 }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
          >
            {tabData.find((tab) => tab.id === activeTab)?.content}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Tabs;
