import React from 'react';
import Card from '../../../components/Card';

const Sem5 = ({ branch }) => {
  const notes = [
    {title: 'Compiler Design', link: '/notes/cse/CD'},
    {title: 'Computer Networks', link: '/notes/cse/CN'},
    {title: 'Design Analysis and Algorithms', link: '/notes/cse/DAA'},
    {title: 'Economics for Engineers', link: '/notes/cse/Economics'},
    {title: 'Operating Systems', link: '/notes/cse/OS'},
    {title: 'Software Engineering', link: '/notes/cse/SE'}
    

  ];
  return (
    <>
      <section className='sem'>
        <h1> CSE-Vth Semester Notes</h1>
      </section>
      <section className='cards-container'>
        {notes.map((note, index) =>
        <Card key={index} title={note.title} link={note.link}/>
        )}
      </section>
    </>
  )
}

export default Sem5;
