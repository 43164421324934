import React from 'react';

const Sem4 = ({ branch }) => {
  return (
    <div>
      <h1>{branch.toUpperCase()} - 4th Semester</h1>
      <p>Welcome to {branch.toUpperCase()} 4th Semester</p>
    </div>
  );
};

export default Sem4;
